body > iframe {
  display: none !important;
  pointer-events: none !important;
}

.roundedImg {
  border-radius: 12px !important;
  height: 450px !important;
  margin-top: 100px;
}
.css-1matj2p-MuiStack-root {
  display: none !important;
}

.Iconbg:hover {
  cursor: pointer;
  background-color: rgb(194, 193, 193);
  padding: 1px;
  border-radius: 100%;
  font-size: 27px;
  color: #456ab2 !important;
  transition: 0.2s ease-in;
}
.Iconbg {
  cursor: pointer;
  font-size: 27px;
  color: gray !important;
}

.deleteIcon:hover {
  color: red !important;
}

.redColor {
  color: red;
}
.d-flex {
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
}

.thumbnailImage{
  border: 1px solid rgb(189, 189, 189) !important;
  border-radius: 5px;
  box-shadow: #000000;
  /* max-width: ; */
}

.thumbnailtext{
  text-align: center;
  margin-top: 30px;
}

.uploadingbtns{
  height: 80px;
  width: 200px;
}

.ImagecloseButton {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 25px;
  padding: 3px 9px;
  background-color: #456ab2;
  color: aliceblue;
  border: none;
  border-radius: 50%;
  transition: .2s ease-in-out;
}
.ImagecloseButton:hover {
  background-color: #ffffff;
  color: rgb(0, 92, 172);
  cursor: pointer;
}

.hoverImg:hover{
    cursor: pointer;
}


.plus_icon,.delete_icon{
  font-size: 50px;
  color: #456ab2;

}
.plus_icon:hover{
cursor: pointer;
color: #000000;
}
.delete_icon:hover{
cursor: pointer;
color: #ff0000;
}
.text-bold{
  font-weight: bolder;
}

.img_flex{
position: relative;
}
.all-images-d-flex{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
}

.product_images{
  width: 200px;
margin: 10px;
box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.x-button{
position: absolute;
top: 0;
right: 0;
background-color: #ff0000 !important;
color: white;
padding: 1px 8px;
font-size: 20px;
border-radius: 50%;
border: 2px solid transparent;
transition: .2s ease-in-out;
font-weight: 600;
}
.x-button:hover{

background-color:white !important;
color: #ff0000;
border: 2px solid #ff0000;
cursor: pointer;
}

.orderImages{
  display: flex !important;
  flex-direction: row !important;
}

.uploadvideosbtn{
  height: 50px !important;
  margin-bottom: 20px !important;
}

.modalvideo{
  width: 300px !important;
  max-height: 200px !important;
  margin-top: 50px !important;
}

.video-react-big-play-button-left{
  display: flex !important;
  align-items: center !important;
  justify-content: center!important;
}


.thumbnailImage{
  width: 300px;
  height: 300px;
  object-fit: cover;
  margin: 15px auto;
}


.video-js {
  width: 499px !important;
  max-height: 100px !important ;
  height: 200px !important;
  margin: 0 auto;
  aspect-ratio: 16 / 9;
}

.vjs_video_3-dimensions.vjs-fluid:not(.vjs-audio-only-mode) {
  padding-top: 500px !important;
}

.storyImage{
width: 100%;
max-width: 300px;
margin: auto;
}
body > iframe {
  display: none !important;
  pointer-events: none !important;
}

.roundedImg {
  border-radius: 12px !important;
  height: 450px !important;
  margin-top: 100px;
}
.css-1matj2p-MuiStack-root {
  display: none !important;
}

.Iconbg:hover {
  cursor: pointer;
  background-color: rgb(194, 193, 193);
  padding: 1px;
  border-radius: 100%;
  font-size: 27px;
  color: #456ab2 !important;
  transition: 0.2s ease-in;
}
.Iconbg {
  cursor: pointer;
  font-size: 27px;
  color: gray !important;
}

.deleteIcon:hover {
  color: red !important;
}

.redColor {
  color: red;
}
.d-flex {
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
}

.thumbnailImage{
  border: 2px solid green !important;
}

.uploadingbtns{
  height: 80px;
  width: 200px;
}

.ImagecloseButton {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 25px;
  padding: 3px 9px;
  background-color: #456ab2;
  color: aliceblue;
  border: none;
  border-radius: 50%;
  transition: .2s ease-in-out;
}
.ImagecloseButton:hover {
  background-color: #ffffff;
  color: rgb(0, 92, 172);
  cursor: pointer;
}

.hoverImg:hover{
    cursor: pointer;
}


.plus_icon,.delete_icon{
  font-size: 50px;
  color: #456ab2;

}
.plus_icon:hover{
cursor: pointer;
color: #000000;
}
.delete_icon:hover{
cursor: pointer;
color: #ff0000;
}
.text-bold{
  font-weight: bolder;
}

.img_flex{
position: relative;
}
.all-images-d-flex{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
}

.product_images{
  width: 200px;
margin: 10px;
box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.x-button{
position: absolute;
top: 0;
right: 0;
background-color: #ff0000 !important;
color: white;
padding: 1px 8px;
font-size: 20px;
border-radius: 50%;
border: 2px solid transparent;
transition: .2s ease-in-out;
font-weight: 600;
}
.x-button:hover{

background-color:white !important;
color: #ff0000;
border: 2px solid #ff0000;
cursor: pointer;
}

.orderImages{
  display: flex !important;
  flex-direction: row !important;
}

.uploadvideosbtn{
  height: 50px !important;
  margin-bottom: 20px !important;
}

.modalvideo{
  width: 300px !important;
  max-height: 200px !important;
  margin-top: 50px !important;
}

.video-react-big-play-button-left{
  display: flex !important;
  align-items: center !important;
  justify-content: center!important;
}

.story_description{
  background-color: #ffffff;
  padding: 10px;
  width: 300px;
  margin: auto;
  height: auto;
  overflow-y: auto;
  border-radius: 5px;
  word-wrap: break-word;
}